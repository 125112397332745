import {PieChart} from '@mui/x-charts/PieChart';
import {Card, CardContent, CardHeader, Grid, MenuItem, Select, Tab, Tabs, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {axisClasses, BarChart} from "@mui/x-charts";

const StatsDisplay = ({data}) => {
    const [width, setWidth] = useState(0);
    const [tier, setTier] = useState(1);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleResize)

        handleResize()

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [setWidth])

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    if (!data) {
        return (<h1>one sec...</h1>)
    }

    function smallScreen() {
        return width < 600;
    }

    function cardWidth() {
        return smallScreen() ? (width - 20) : 500;
    }

    const tierData = data[`Tier ${tier}`];

    if (!tierData) {
        return null;
    }

    const chartHeight = 330 + (tierData['pick_breakdown'].length * 20);

    function roundTabs() {
        let out = [];
        for (let i = 1; i < 5; i++) {
            if (data[`Round ${i}`]) {
                out.push(<Tab label={`Round ${i}`}></Tab>)
            }
        }
        return out;
    }

    const round = tab - 1;

    const roundData = data[`Round ${round}`];

    return (<div>
        <Tabs value={tab} onChange={handleTabChange} sx={{marginLeft: '20px'}}>
            <Tab label="Tiers"></Tab>
            <Tab label="Tiebreaker"></Tab>
            {roundTabs()}
        </Tabs>
        <div hidden={tab !== 0}>
            <Grid container justifyContent="center">
                <Card sx={{
                    width: (cardWidth() - 5), marginBottom: '20px', marginTop: '20px'
                }}>
                    <CardContent>
                        <Select
                            value={tier}
                            onChange={(e) => setTier(e.target.value)}
                        >
                            {Array.from({length: 6}, (_, i) => i + 1).map((tier) => (
                                <MenuItem key={tier} value={tier}>{`Tier ${tier}`}</MenuItem>))}
                        </Select>
                        <PieChart
                            series={[{
                                'data': tierData['pick_breakdown'],
                                innerRadius: 20,
                                outerRadius: 100,
                                paddingAngle: 1,
                                cornerRadius: 3,
                                cx: smallScreen() ? (cardWidth() / 2 - 20) : 150,
                                cy: smallScreen() ? cardWidth() / 2 : null,
                            }]}
                            width={cardWidth()}
                            height={smallScreen() ? chartHeight : 420}
                            slotProps={{
                                legend: {
                                    itemGap: 3, itemMarkHeight: 12, itemMarkWidth: 12, padding: 50, position: {
                                        vertical: smallScreen() ? 'bottom' : 'middle',
                                        horizontal: smallScreen() ? 'middle' : 'right',
                                    }
                                }
                            }}
                        />
                        <Typography variant="h6" component="h2">
                            {`${tierData['players_picked']} Players Picked`}
                        </Typography>
                    </CardContent>
                    <CardContent hidden={!tierData['players_not_picked'].length}>
                        <Typography variant="h8" component="h4">
                            {`Players not picked: ${tierData['players_not_picked'].join(', ')}`}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </div>
        <div hidden={tab !== 1}>
            <Grid container justifyContent="center">
                <Card sx={{
                    marginBottom: '20px', marginTop: '20px'
                }}>
                    <CardHeader title="Tiebreaker"></CardHeader>
                    <CardContent>
                        <BarChart
                            dataset={data['tiebreaker']['distribution']}
                            yAxis={[{dataKey: 'score', scaleType: 'band', label: 'Tiebreaker Score'}]}
                            series={[{dataKey: 'count'}]}
                            xAxis={[{label: 'Count'}]}
                            layout="horizontal"
                            width={cardWidth()}
                            height={smallScreen() ? chartHeight : 420}
                            sx={{
                                [`.${axisClasses.left} .${axisClasses.label}`]: {
                                    // Move the y-axis label with CSS
                                    transform: 'translateX(-5px)',
                                },
                            }}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </div>
        <div hidden={tab < 2}>
            <Grid container justifyContent="center">
                <Card sx={{
                    marginBottom: '20px', marginTop: '20px'
                }}>
                    <CardHeader title="Score Distribution"></CardHeader>
                    <CardContent>
                        {roundData && (<BarChart
                            dataset={roundData}
                            yAxis={[{dataKey: 'score', scaleType: 'band', label: 'To Par'}]}
                            series={[{dataKey: 'count'}]}
                            xAxis={[{label: 'Count'}]}
                            layout="horizontal"
                            width={cardWidth()}
                            height={smallScreen() ? chartHeight : 420}
                            sx={{
                                [`.${axisClasses.left} .${axisClasses.label}`]: {
                                    // Move the y-axis label with CSS
                                    transform: 'translateX(-5px)',
                                },
                            }}
                        />)}
                    </CardContent>
                </Card>
            </Grid>
        </div>
    </div>);
};

export default StatsDisplay;
