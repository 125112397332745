import axios from "axios";

export const fetchData = async (url, params = null) => {
    try {
        // check if url has a leading slash, if not add one
        if (url[0] !== '/') {
            url = `/${url}`;
        }
        const baseURL = process.env.NODE_ENV === 'production' ? '' : 'http://192.168.1.157:3069';
        const response = await axios.get(`${baseURL}/api${url}`, {params});
        return response?.data;
    } catch (error) {
        console.error(error);
    }
};
