import PropTypes from "prop-types";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";

export default function PlayerDialog(props) {
    const {open, data, name, handleClose} = props;

    const columns = data && data.length > 0 ? Object.keys(data[0]).filter(col => col !== 'id').map((col) => {
        const extra = col === 'Picks' ? {minWidth: 160} : {};
        return Object.assign({field: col, headerName: col}, extra);
    }) : [];

    return (<Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle>{name}</DialogTitle>
        <DialogContent>
            <DataGrid
                rows={data || []}
                columns={columns}
                autoHeight={true}
                hideFooter={true}
            />
        </DialogContent>
    </Dialog>)
};

PlayerDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
};

PlayerDialog.defaultProps = {
    data: [],
};
