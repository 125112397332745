import {DataGrid} from '@mui/x-data-grid';
import PlayerDialog from "./playerDialog";
import {useState} from "react";
import {CircularProgress, Snackbar} from "@mui/material";
import {fetchData} from "../api";

const Leaderboard = ({data}) => {
    const [open, setOpen] = useState(false);
    const [playerData, setPlayerData] = useState([{}]);
    const [player, setPlayer] = useState('');
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(true);

    if (!data || data[0] === undefined) {
        return (<h1>one sec...</h1>)
    }

    const columns = Object.keys(data[0]).filter(col => col !== 'id').map((col) => {
        if (col === 'RANK') {
            return {field: col, headerName: "Rank", width: 55}
        } else if (col === 'name') {
            return {field: col, headerName: 'Name', flex: 1, minWidth: 150}
        } else if (col === 'TOTAL') {
            return {field: col, headerName: 'Total', flex: 1, minWidth: 55}
        } else if (col.toLowerCase().includes('projected')) {
            return {field: col, headerName: col, flex: 1, minWidth: 175}
        }
        return {field: col, headerName: col, flex: 1, minWidth: 170}
    });

    const handleRowClick = async (params) => {
        setLoading(true);
        const response = await fetchData('/picks_scores', {name: params.row.name});
        setPlayer(params.row.name);
        setPlayerData(response);
        setLoading(false);
        setOpen(true);
    };

    return (<div style={{width: '95%', marginBottom: '50px'}}>
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={5000}
            onClose={() => setSnackbarOpen(false)}
            message={'Click a row for more info'}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        />
        <DataGrid
            rows={data}
            columns={columns}
            autoHeight={true}
            hideFooter={true}
            onRowClick={handleRowClick}
        />
        {loading && (<CircularProgress
            size={75}
            sx={{
                position: 'fixed', left: '50%', zIndex: 1000, top: '50%'
            }}
        />)}
        <PlayerDialog
            open={open}
            name={player}
            data={playerData}
            handleClose={() => setOpen(false)}
        />
    </div>);
};

export default Leaderboard;