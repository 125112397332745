import {AppBar, createTheme, Grid, Tab, Tabs, ThemeProvider} from '@mui/material';
import Leaderboard from './components/leaderboard'
import './App.css';
import {useEffect, useState} from "react";
import {fetchData} from "./api";
import StatsDisplay from "./components/stats";

const theme = createTheme({
    palette: {
        primary: {
            main: '#0f6644',
        }
    }
})

function App() {
    const [data, setData] = useState([]);
    const [status, setStatus] = useState('');
    const [cutData, setCut] = useState([]);
    const [tab, setTab] = useState(0);
    const [statsData, setStats] = useState({});

    function isTimeToShow() {
        const now = new Date();
        const est = new Date(now.toLocaleString("en-US", {timeZone: "America/New_York"}));
        const targetDate = new Date('April 11, 2024 06:00:00 EDT');
        return est > targetDate;
    }

    useEffect(() => {
        const fetchAllData = async () => {
            const leaderboardData = isTimeToShow() ? await fetchData('/leaderboard') : [];
            setData(leaderboardData);

            const statusData = await fetchData('/status');
            setStatus(statusData);

            const cutData = await fetchData('/projected_cut');
            setCut(cutData);

            const statsData = await fetchData('/stats');
            setStats(statsData);
        };

        // noinspection JSIgnoredPromiseFromCall
        fetchAllData();
    }, [])

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const cutText = cutData && cutData[0] ? `Projected Cut ${cutData[1]}` : cutData && cutData[1] ? `Cut ${cutData[1]}` : null;

    return (<ThemeProvider theme={theme}>
        <div className="App">
            <AppBar position="static">
                <h2 align="left" style={{paddingLeft: '5px'}}>Masters Pool 2024</h2>
            </AppBar>
            <Tabs value={tab} onChange={handleTabChange}>
                <Tab label="Leaderboard">
                </Tab>
                <Tab label="Stats 🆕"></Tab>
            </Tabs>
            <div hidden={tab !== 0}>
                <Grid container>
                    <Grid item xs={6}>
                        <h3 align="left" style={{paddingLeft: '20px'}}>Leaderboard <br/> {status}</h3>
                    </Grid>
                    <Grid item xs={6}>
                        <h3 align="right" style={{paddingRight: '20px'}}>{cutText}</h3>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'} style={{paddingTop: '10px', paddingBottom: '5px'}}>
                    {isTimeToShow() ? <Leaderboard data={data}/> : <div>
                        <h1>Stay tuned! Leaderboard will be available Thursday at 6am EST.</h1>
                        <h3>If you can't wait, check out a <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>preview
                            here</a>.
                        </h3>
                    </div>}
                </Grid>
            </div>
            <div hidden={tab !== 1}>
                <StatsDisplay data={statsData}/>
            </div>
        </div>
    </ThemeProvider>);
}

export default App;
